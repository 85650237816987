























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/molecules/Modal.vue'
import Spinner from '@/components/atoms/Spinner.vue'
import orgGroupsStore from '@/store/OrganizationGroups'

@Component({ components: { Modal, Spinner } })
export default class extends Vue {
  @Prop({ type: String, required: true }) orgaizationGroupId!: string

  isSuccess = false
  isLoading = false
  errorMessage = ''
  modalClose(): void {
    const modal = this.$refs.modal as Modal
    modal.modalClose()
    this.isSuccess = false
  }
  async deleteGroup(): Promise<void> {
    this.isLoading = true

    try {
      await orgGroupsStore.deleteNoGroupCheck(this.orgaizationGroupId)
      orgGroupsStore.fetchOrgGroupsNoGroupCheck()
      this.isSuccess = true
    } catch (e) {
      const error = e as Error
      this.errorMessage = error.message
    } finally {
      this.isLoading = false
    }
  }
}
