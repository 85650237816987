

































import Status from '@/components/atoms/Group/Status.vue'
import DeleteModal from '@/components/organisms/GroupAdjustment/DeleteModal.vue'
import contractsStore from '@/store/Contracts'
import { OrganizationGroup, Status as OrgGroupStatus } from '@/store/GroupSettings'
import orgGroupsStore from '@/store/OrganizationGroups'
import VABox from 'va/widgets/VABox.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { VABox, DeleteModal, Status } })
export default class extends Vue {
  isDeleted(status: string): boolean {
    return status === OrgGroupStatus.DELETED
  }

  groupName(name: string, status: string): string {
    return this.isDeleted(status) ? '-' : name
  }

  invitationId(invitationId: string, status: string): string {
    return this.isDeleted(status) ? '-' : invitationId
  }

  get groupData(): OrganizationGroup[] {
    return orgGroupsStore.orgGroupsNoGroupCheck
  }

  get maxGroups(): number | null {
    return contractsStore.maxGroupsNoGroupCheck
  }

  get isOverMaxGroups(): boolean {
    if (this.maxGroups === null) {
      return false
    }
    return this.groupData.length > this.maxGroups
  }
}
