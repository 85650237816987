









import { Component, Vue } from 'vue-property-decorator'
import GroupTable from '@/components/organisms/GroupAdjustment/GroupTable.vue'
import menuStore from '@/store/Menu'
import orgGroupsStore from '@/store/OrganizationGroups'
import contractsStore from '@/store/Contracts'

@Component({ components: { GroupTable } })
export default class extends Vue {
  async mounted(): Promise<void> {
    menuStore.setInvisible()
    await Promise.all([orgGroupsStore.fetchOrgGroupsNoGroupCheck(), contractsStore.fetchMaxGroupsNoGroupCheck()])
  }

  get maxGroups(): number | null {
    return contractsStore.maxGroupsNoGroupCheck
  }

  get isOverMaxGroups(): boolean {
    if (this.maxGroups === null) {
      return false
    }
    return orgGroupsStore.orgGroupsNoGroupCheck.length > this.maxGroups
  }

  move(): void {
    this.$router.push({
      name: 'Home',
    })
  }
}
